* {
  font-family: "Roboto","Helvetica","Arial",sans-serif;
}

.App {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}
